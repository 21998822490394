import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import footerimg from '../../assets/images/home/footerimg.png';
import logo2 from '../../assets/images/home/Logo2.png';
import CallIcon from '@mui/icons-material/Call';
import { useNavigate } from 'react-router-dom';


function Footer() {

  const navigate = useNavigate('')

  return (
    <Box mt={20}>
      <Box sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.93), rgba(0, 0, 0, 0.93)), url(${footerimg})`,
        width: "100%",
        objectFit: "cover",
        paddingTop: "40px",
        position: "relative"
      }}>
        <Box sx={{
          borderBottom: "1px solid #FFFFFF40",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <Container maxWidth='xl'>
            <Box>
              <Box sx={{display: 'flex',justifyContent: { xs: 'start', md: 'center' },position: 'absolute',top: -40,left: '50%',transform: "translateX(-50%)",}}>
                <Box sx={{ display: { xs: 'block', md: 'flex' }, justifyContent: 'center', alignItems: 'center', backgroundColor: '#E61F26', color: 'white', padding: { xs: '5px 5px', md: '20px 40px' } }}>
                  <Typography sx={{fontFamily: "'Lato', sans-serif",fontSize: { xs: '14px', md: '20px' },paddingRight: { xs: '0px', md: '80px' }}}>Get more information, request a quote today!</Typography>
                  <Button sx={{bgcolor: "#fff",color: "#E61F26",fontFamily:"'Lato', sans-serif",fontWeight:{xs:'400',md:'600'},fontSize:{xs:'12px',md:'14px'}}}>CONTACT US</Button>
                </Box>
              </Box>
            </Box>
            <Grid container spacing={3} py={5} display={"flex"} justifyContent={"center"}>
              <Grid item xs={12} sm={12} md={12} lg={3} display={"flex"} justifyContent={"center"}>
                <Box px={{ xs: 2, md: 0}} >
                 <Box sx={{display:"flex",justifyContent:"center"}}>
                  <img src={logo2} alt="" style={{ maxWidth: '100%', height: 'auto'}}/>
                 </Box>
                  <Typography sx={{
                    color: "white",
                    fontFamily: "'Lato', sans-serif",
                    fontSize: { xs: "16px", sm: "18px", md: "18px" },
                    display: "flex",
                    textAlign :{lg:"start",sm:"center",xs:"center"},
                    py: { xs: 1, md: 2 }
                  }}>Providing innovative fire safety solutions to protect lives, property, and ensure peace of mind.</Typography>
                  <Box>
                    <Box sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent:{lg:"start",sm:"center",xs:"center"},
                      width: "100%",
                      // mx: 'auto'
                    }}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: "center",
                        gap: "20px",
                        backgroundColor: "#E61F26",
                        padding: "5px 20px"
                      }}>
                        <CallIcon sx={{ color: "white", fontSize: { xs: "28px", md: "40px" } }} />
                        <Box>
                          <Typography sx={{ color: "white", fontFamily: "'Lato', sans-serif", fontSize: { xs: '16px', md: '18px' } }}>Emergency Call</Typography>
                          <Typography sx={{ color: "white", fontFamily: "'Lato', sans-serif", fontSize: { xs: '17px', md: '22px' } }}>020 3797 3053</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={2} display={"flex"} justifyContent={"center"}>
                <Box>
                  <Typography sx={{ color: "white", fontFamily: "'Lato', sans-serif", fontWeight: '700', fontSize: { xs: '22px', md: '24px' }, mb: "10px" }}>Quick Links</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' }}} onClick={() => navigate("/")}>Home</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' }}} onClick={() => navigate("/aboutus")}>About</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' }}} onClick={() => navigate("/ourservices")}>Our Services</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' }}} onClick={() => navigate("/contactus")}>Contact us</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' }}} onClick={() => navigate("/support")}>Support</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={2} display={"flex"} justifyContent={"center"}>
                <Box>
                  <Typography sx={{ color: "white", fontFamily: "'Lato', sans-serif", fontWeight: '700', fontSize: { xs: '22px', md: '24px' }, mb: "10px" }}>Our Services</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' } }}>Fire Detector</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' } }}>Fire Extinguisher</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' } }}>Fire Hose Reels</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' } }}>Manual Call Point</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' } }}>Hydrant System</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' } }}>Fire Blankets</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={2} display={"flex"} justifyContent={"center"}>
                <Box>
                  <Typography sx={{ color: "white", fontFamily: "'Lato', sans-serif", fontWeight: '700', fontSize: { xs: '22px', md: '24px' }, mb: "10px" }}>Useful Links</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' } }} onClick={() => navigate("/privacypolicy")}>Privacy Policy</Typography>
                    <Typography sx={{ cursor:"pointer",color: "white", fontFamily: "'Lato', sans-serif", margin: { xs: "8px 0px", md: "12px 0px" }, fontSize: { xs: '14px', md: '16px' } }} onClick={() => navigate("/termsconditions")}>Terms & Conditions</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} display={"flex"} justifyContent={"center"}>
                <Box>
                  <Typography sx={{ color: "white", fontFamily: "'Lato', sans-serif", fontWeight: '700', fontSize: { xs: '22px', md: '24px' }, mb: "18px" }}>Find on Map</Typography>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2308.365936665221!2d72.82693873883674!3d21.171720996295814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04e3e232f71b3%3A0x60665048e205ee80!2sSosyo%20Circle%2C%20Udhana%2C%20Surat%2C%20Gujarat%20395017!5e1!3m2!1sen!2sin!4v1727097371929!5m2!1sen!2sin" width="100%" height="200px" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box>
          <Container maxWidth='xl'>
            <Box py={3} sx={{ display: { xs: "block",sm:"flex", md: "flex" }, alignItems: "center", justifyContent: "space-between" }}>
              <Typography sx={{ color: "white", textAlign: { xs: "center", md: 'start' } }}>Copyright 2024. All rights reserved</Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "15px", justifyContent: { xs: "center" }, pt: { xs: 2, sm: 0, md: 0 }, fontSize: "20px" }}>
                <i className="fa-brands fa-google" style={{ color: 'white' }}></i>
                <i className="fa-brands fa-facebook-f" style={{ color: 'white' }}></i>
                <i className="fa-brands fa-linkedin-in" style={{ color: "white" }}></i>
                <i className="fa-brands fa-whatsapp" style={{ color: 'white' }}></i>
                <i className="fa-solid fa-envelope" style={{ color: 'white' }}></i>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
