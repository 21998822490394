import { Container, Box, Typography } from '@mui/material';
import image from '../../assets/images/contact/contactBG.png'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function ContactHerosection() {
  return (
    <div>
    <Box
        sx={{
            backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.755), rgba(0, 0, 0, 0.755), rgba(0, 0, 0, 0)), url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center"
        }}
        width={"100%"}
        height={"600px"}
    >
        <Container maxWidth='xl'>
            <Box>
                <Typography sx={{ color: "#fff", fontSize: "45px", fontWeight: "700", fontFamily: "'Lato',sans-serif" }}>
                    Contant Us
                </Typography>
                <Typography sx={{ color: "red", fontSize: "25px", fontFamily: "'Lato',sans-serif", alignItems: "center", display: "flex" }}>
                    Home <ChevronRightIcon fontSize='large' /> Contact Us
                </Typography>
            </Box>
        </Container>
    </Box>
</div>
  );
}

export default ContactHerosection;


