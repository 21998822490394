import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import EastIcon from '@mui/icons-material/East';
import { Container, Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/images/home/logo.png';

function Navbar() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const isActive = (path) => currentPath === path;

  const drawerList = (
    <Box
      sx={{ width: 250, backgroundColor: "black", height: "100%" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box display={"flex"} alignItems={"center"} py={2} justifyContent={"space-around"} sx={{ backgroundColor: "white" }}>
        <Box sx={{ display: { xs: 'block', md: 'none' }, mr: 1 }}>
          <img src={logo} alt="Logo" width={"150px"} onClick={() => navigate("/")} />
        </Box>
        <IconButton onClick={toggleDrawer(false)} sx={{ color: "white", backgroundColor: "black", borderRadius: "8px" }} >
          <EastIcon />
        </IconButton>
      </Box>
      <MenuItem onClick={() => navigate("/")} sx={{ backgroundColor: isActive('/') ? '#E61F26' : 'transparent' }}>
        <Typography textAlign="center" sx={{ cursor: 'pointer', color: 'white', fontFamily: "'Lato', sans-serif", "&:hover": { color: "#E61F26" } }}>
          Home
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate("/ourservices")} sx={{ backgroundColor: isActive('/ourservices') ? '#E61F26' : 'transparent' }}>
        <Typography textAlign="center" sx={{ cursor: 'pointer', color: 'white', fontFamily: "'Lato', sans-serif", "&:hover": { color: "#E61F26" } }}>
          Our Services
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate("/aboutus")} sx={{ backgroundColor: isActive('/aboutus') ? '#E61F26' : 'transparent' }}>
        <Typography textAlign="center" sx={{ cursor: 'pointer', color: 'white', fontFamily: "'Lato', sans-serif", "&:hover": { color: "#E61F26" } }}>
          About Us
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate("/contactus")} sx={{ backgroundColor: isActive('/contactus') ? '#E61F26' : 'transparent' }}>
        <Typography textAlign="center" sx={{ cursor: 'pointer', color: 'white', fontFamily: "'Lato', sans-serif", "&:hover": { color: "#E61F26" } }}>
          Contact Us
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate("/support")} sx={{ backgroundColor: isActive('/support') ? '#E61F26' : 'transparent' }}>
        <Typography textAlign="center" sx={{ cursor: 'pointer', color: 'white', fontFamily: "'Lato', sans-serif", "&:hover": { color: "#E61F26" } }}>
          Support
        </Typography>
      </MenuItem>
    </Box>
  );

  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Container maxWidth='xl'>
        <AppBar position='static' sx={{ backgroundColor: "white", boxShadow: "none", padding: "8px 0px" }}>
          <Toolbar disableGutters>
            <Grid container item xs={12} alignItems="center">
              <Grid item xs={6} md={3} display="flex" justifyContent={{ xs: 'flex-start', md: 'flex-start' }}>
                <Box sx={{ display: { xs: 'none', md: 'block' }, mr: 1 }}>
                  <img src={logo} alt="Logo" onClick={() => navigate("/")} />
                </Box>
                <Box sx={{ display: { xs: 'block', md: 'none' }, mr: 1 }}>
                  <img src={logo} alt="Logo" width={"150px"} onClick={() => navigate("/")} />
                </Box>
              </Grid>
              <Grid item xs={6} md={9} display="flex" justifyContent={{ xs: 'flex-end', md: 'flex-start' }} alignItems="center">
                <IconButton
                  size="large"
                  aria-label="open navigation menu"
                  aria-haspopup="true"
                  onClick={toggleDrawer(true)}
                  sx={{ color: 'black', display: { xs: 'flex', md: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                  sx={{ marginTop: "100px" }}
                >
                  {drawerList}
                </Drawer>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 0 }}>
                  <Typography
                    sx={{
                      my: 2,
                      color: isActive('/') ? '#E61F26' : 'black',
                      display: 'block',
                      fontWeight: 700,
                      fontSize: '18px',
                      cursor: "pointer",
                      fontFamily: "'Lato', sans-serif",
                      padding: { lg: '0px 30px', md: '0px 20px' },
                      "&:hover": { color: "#E61F26", transition: ".3s" }
                    }}
                    onClick={() => navigate("/")}
                  >
                    Home
                  </Typography>
                  <Typography
                    sx={{
                      my: 2,
                      color: isActive('/ourservices') ? '#E61F26' : 'black',
                      display: 'block',
                      fontWeight: 700,
                      fontSize: '18px',
                      cursor: "pointer",
                      fontFamily: "'Lato', sans-serif",
                      padding: { lg: '0px 30px', md: '0px 20px' },
                      "&:hover": { color: "#E61F26", transition: ".3s" }
                    }}
                    onClick={() => navigate("/ourservices")}
                  >
                    Our Services
                  </Typography>
                  <Typography
                    sx={{
                      my: 2,
                      color: isActive('/aboutus') ? '#E61F26' : 'black',
                      display: 'block',
                      fontWeight: 700,
                      fontSize: '18px',
                      cursor: "pointer",
                      fontFamily: "'Lato', sans-serif",
                      padding: { lg: '0px 30px', md: '0px 20px' },
                      "&:hover": { color: "#E61F26", transition: ".3s" }
                    }}
                    onClick={() => navigate("/aboutus")}
                  >
                    About Us
                  </Typography>
                  <Typography
                    sx={{
                      my: 2,
                      color: isActive('/contactus') ? '#E61F26' : 'black',
                      display: 'block',
                      fontWeight: 700,
                      fontSize: '18px',
                      cursor: "pointer",
                      fontFamily: "'Lato', sans-serif",
                      padding: { lg: '0px 30px', md: '0px 20px' },
                      "&:hover": { color: "#E61F26", transition: ".3s" }
                    }}
                    onClick={() => navigate("/contactus")}
                  >
                    Contact Us
                  </Typography>
                  <Typography
                    sx={{
                      my: 2,
                      color: isActive('/support') ? '#E61F26' : 'black',
                      display: 'block',
                      fontWeight: 700,
                      fontSize: '18px',
                      cursor: "pointer",
                      fontFamily: "'Lato', sans-serif",
                      padding: { lg: '0px 30px', md: '0px 20px' },
                      "&:hover": { color: "#E61F26", transition: ".3s" }
                    }}
                    onClick={() => navigate("/support")}
                  >
                    Support
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Container>
    </Box>
  );
}

export default Navbar;
