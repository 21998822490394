import React from 'react';
import Header from '../commoncomponent/header/Header';
import Navbar from '../commoncomponent/header/Navbar';
import AboutHerosection from '../components/aboutus/AboutHerosection';
import Emergency from '../components/aboutus/Emergency';
import ProtectingLives from '../components/aboutus/ProtectingLives';
import TheirTestimonials from '../components/aboutus/TheirTestimonials';
import TeamMembers from '../components/aboutus/TeamMembers';
import Footer from '../commoncomponent/footer/Footer';

function AboutUs() {
  return (
    <div>
       <Header />
       <Navbar />
       <AboutHerosection />
       <Emergency />
       <ProtectingLives />
       <TheirTestimonials />
       <TeamMembers />
       <Footer />
    </div>
  );
}

export default AboutUs;
