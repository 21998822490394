import React from 'react';
import { Container, Box, Typography, Grid, Paper, Button } from '@mui/material';
import  homeservice1  from '../../assets/images/service/service1.png';
import  homeservice2  from '../../assets/images/service/service2.png';
import  homeservice3  from '../../assets/images/service/service3.png';
import  homeservice4  from '../../assets/images/service/service4.png';
import  homeservice5  from '../../assets/images/service/service5.png';
import  homeservice6  from '../../assets/images/service/service6.png';
import service1 from '../../assets/images/service/Group-1.png'
import service2 from '../../assets/images/service/Group-2.png'
import service3 from '../../assets/images/service/Group-3.png'
import service4 from '../../assets/images/service/Group-4.png'
import service5 from '../../assets/images/service/Group-5.png'
import service6 from '../../assets/images/service/Group-6.png'
import hero1 from '../../assets/images/service/sHero-1.png'
import hero2 from '../../assets/images/service/sHero-2.png'
import hero3 from '../../assets/images/service/sHero-3.png'
import hero4 from '../../assets/images/service/sHero-4.png'
import hero5 from '../../assets/images/service/sHero-5.png'
import hero6 from '../../assets/images/service/sHero-6.png'
import  servicemap from '../../assets/images/service/map.png';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate } from 'react-router-dom';


export const ourservice = [
  {
    id:1,
    img2:service1,
    shero:hero1,
    heading2:"Reliable Fire Detectors Ensuring Maximum Protection Every Time",
    img: homeservice1,
    heding: "Detector",
    heding3: "Fire",
  },
  {
    id:2,
    img2:service2,
    shero:hero2,
    heading2:"Providing reliable fire extinguisher solutions, ensuring safety and protection for homes and businesses worldwide.",
    img: homeservice2,
    heding: "Extinguisher",
    heding3: "Fire",
  },
  {
    id:3,
    img2:service3,
    shero:hero3,
    heading2:"Providing reliable fire hose reel solutions to safeguard lives and property with trusted protection.",
    img: homeservice3,
    heding: "Hose Reels",
    heding3: "Fire",
  },
  {
    id:4,
    img2:service4,
    shero:hero4,
    heading2:"Ensuring reliable emergency response with advanced manual call points for fire safety solutions worldwide.",
    img: homeservice4,
    heding: " Call Point",
    heding3: "Manual",
  },
  {
    id:5,
    img2:service5,
    shero:hero5,
    heading2:"Reliable hydrant systems ensuring comprehensive fire safety, protection, and peace of mind for all.",
    img: homeservice5,
    heding: "System",
    heding3: "Hydrant",
  },
  {
    id:6,
    img2:service6,
    shero:hero6,
    heading2:"Reliable hydrant systems ensuring comprehensive fire safety, protection, and peace of mind for all.",
    img: homeservice6,
    heding: "Blankets",
    heding3: "Fire"
  },
];


function OureService() {

  const navigate = useNavigate()

  return (
    <Box pt={10}>
    <Container maxWidth='xl'>
        <Grid container spacing={5}>
            {ourservice.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                <Box
                  sx={{
                    backgroundImage: `linear-gradient(360deg,rgba(0,0,0,0.955),rgba(0,0,0,0)), url(${item.img})`,
                    width: '100%',
                    height: '300px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: 'flex',
                    alignItems: 'flex-end',
                    cursor:"pointer"
                  }}
                  onClick={() => navigate(`/servicelist/${item.id}`)}
                >
                  <Typography variant='h5' sx={{
                    color: "white", padding: "0px 0px 20px 20px",
                    fontWeight: "900", fontFamily: "'Lato',sans-serif"
                  }}>
                    {item.heding3} {item.heding}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        <Box pt={10} sx={{width:"100%",display:"flex",justifyContent:"center"}}>
          <Box sx={{width:"80%"}}>
            <Paper sx={{backgroundImage:`url(${servicemap})`,width:"100%",backgroundRepeat:"no-repeat",p:{lg:5,md:5,sm:5,xs:1},borderTop:"10px solid red"}}>
                 <Box sx={{display:{lg:"flex",md:"flex",sm:"block",xs:"block"},justifyContent:"space-between",alignItems:"center",}}>
                    <Box sx={{display:{lg:"flex",md:"flex",sm:"block",xs:"block"},alignItems:"center",justifyContent:"center",textAlign:{lg:"start",md:"start",sm:"center",xs:"center"},gap:{lg:5,md:3,xs:2} }}>
                         <PhoneIcon sx={{ fontSize:{ xs: "40px ", md: "50px",lg:"70px"},color: "#E61F26"}} />
                         <Box>
                             <Box sx={{ color: "#E61F26" }}>
                                 <Typography sx={{ fontSize: { xs: "14px", md: "22px" } }}>Need Our Help?</Typography>
                             </Box>
                             <Box sx={{ color: "black" }}>
                                 <Typography sx={{ fontSize: { xs: "20px", sm:"24px",md: "28px",lg:"38px" }, fontWeight: "600",flexWrap:"nowrap"}}>Don't Hesitate To Contact Us</Typography>
                             </Box>
                         </Box>
                    </Box>
                    <Box sx={{display:{lg:"flex",md:"flex",sm:"flex",xs:"block"},justifyContent:"center",textAlign:{lg:"start",md:"start",xs:"center"}}}>
                       <Button sx={{ backgroundColor: "red", padding:{lg: "12px 20px",md: "12px 10px",}, color: "#fff", fontWeight: "500",mt:{xs:1} }}>
                           CONTACT US
                       </Button>
                    </Box>
                 </Box>
            </Paper>
          </Box>
        </Box>
</Container>
  </Box>
  );
}

export default OureService;
