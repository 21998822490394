import React from 'react';
import ServiceHerosection from '../components/service/ServiceHerosection';
import Clientsinquestions from '../components/service/Clientsinquestions';
import OureService from '../components/service/OureService';
import Header from '../commoncomponent/header/Header';
import Navbar from '../commoncomponent/header/Navbar';
import Footer from '../commoncomponent/footer/Footer';
import ServiceList from '../components/service/ServiceList';

function Service() {
  return (
    <div>
      <Header />
      <Navbar />
      <ServiceHerosection/>
      <OureService />
      <Clientsinquestions />
      {/* <ServiceList /> */}
      <Footer />
    </div>
  );
}

export default Service;
