import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import image from '../../assets/images/contact/contactBG.png'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Header from '../header/Header';
import Navbar from '../header/Navbar';
import Footer from '../footer/Footer';

function TermsConditions() {
  return (
    <>
    <Header />
    <Navbar />

    <Box
        sx={{
            backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.755), rgba(0, 0, 0, 0.755), rgba(0, 0, 0, 0)), url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center"
        }}
        width={"100%"}
        height={"600px"}
    >
        <Container maxWidth='xl'>   
            <Box>
                <Typography sx={{ color: "#fff", fontSize: "45px", fontWeight: "700", fontFamily: "'Lato',sans-serif" }}>
                    Terms & Conditions
                </Typography>
                <Typography sx={{ color: "red", fontSize: "25px", fontFamily: "'Lato',sans-serif", alignItems: "center", display: "flex" }}>
                    Home <ChevronRightIcon fontSize='large' /> Terms & Conditions
                </Typography>
            </Box>
        </Container>
    </Box>
    <Box sx={{marginTop:"100px"}}>
        <Container maxWidth='xl'>
            <Box sx={{paddingBottom:{xs:'30px', md:'60px'}}}>
                <Typography sx={{fontSize:{xs:'30px', md:'36px'},fontFamily:"'Lato', sans-serif",fontWeight:'700',textDecoration:"underline",textDecorationColor:"#E61F26",textUnderlineOffset: '20px'}}>
                    <span style={{color:'#E61F26'}}>Terms & </span> Conditions
                </Typography>
            </Box>

            <Box sx={{color:'#000000B2',fontFamily:"'Lato', sans-serif"}}>
                <Typography sx={{fontSize:{xs:'16px',sm:'18px',md:'20px',lg:'20px'},paddingBottom:'28px'}}>
                    Welcome to our fire safety website. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions. Please review these carefully before using the site. If you do not agree with these terms, you should not use our website.
                </Typography>

                <Typography sx={{color:'black',fontWeight:'700',fontSize:{xs:'20px',md:'24px'}}}>1. Services</Typography>
                <Typography sx={{fontSize:{xs:'16px',sm:'18px',md:'20px',lg:'20px'},paddingBottom:'28px'}}>
                    Our fire safety recommendations, materials, and services are provided "as-is." We do not accept liability for any direct, indirect, incidental, or consequential damages arising from the use of our website or services. Compliance with fire safety regulations is the responsibility of the individual or organization using our services.
                </Typography>
                
                <Typography sx={{color:'black',fontWeight:'700',fontSize:{xs:'20px',md:'24px'}}}>2. Liability</Typography>
                <Typography sx={{fontSize:{xs:'16px',sm:'18px',md:'20px',lg:'20px'},paddingBottom:'28px'}}>
                    We provide fire safety information, training, and consulting services. While we strive to ensure that all content is accurate and up-to-date, we do not guarantee the completeness or reliability of any information on the website. It is the responsibility of the user to verify all information before relying on it for safety decisions.
                </Typography>

                <Typography sx={{color:'black',fontWeight:'700',fontSize:{xs:'20px',md:'24px'}}}>3. User Responsibility</Typography>
                <Typography sx={{fontSize:{xs:'16px',sm:'18px',md:'20px',lg:'20px'},paddingBottom:'28px'}}>
                    Users agree to use the website in a lawful manner and not to misuse any information, download, or access provided here. We reserve the right to deny access to anyone violating these terms.
                </Typography>
                
                <Typography sx={{color:'black',fontWeight:'700',fontSize:{xs:'20px',md:'24px'}}}>4. Third-Party Links</Typography>
                <Typography sx={{fontSize:{xs:'16px',sm:'18px',md:'20px',lg:'20px'},paddingBottom:'28px'}}>
                    Our website may contain links to external sites for additional resources. We are not responsible for the content, privacy policies, or practices of any third-party websites.
                </Typography>
                
                <Typography sx={{color:'black',fontWeight:'700',fontSize:{xs:'20px',md:'24px'}}}>5. Modifications</Typography>
                <Typography sx={{fontSize:{xs:'16px',sm:'18px',md:'20px',lg:'20px'},paddingBottom:'28px'}}>
                    We reserve the right to modify these terms and conditions at any time without prior notice. By continuing to use the website, you agree to be bound by any such revisions.
                    For further inquiries, please contact us directly.
                </Typography>

            </Box>
        </Container>
    </Box>
    <Footer />
    </>
  )
}

export default TermsConditions
