import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ourservice } from './OureService'
import image from '../../../src/assets/images/aboutus/image.png'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Header from '../../commoncomponent/header/Header'
import Navbar from '../../commoncomponent/header/Navbar'
import Footer from '../../commoncomponent/footer/Footer'


const ServiceList = () => {

const {id} = useParams();
console.log(id);

const ditails = ourservice.find((item) => item.id == id)
    return (
        <div>
        <Header />
        <Navbar />
            <Box
                sx={{
                    backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.755), rgba(0, 0, 0, 0.655), rgba(0, 0, 0, 0)), url(${ditails?.shero})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    alignItems: "center"
                }}
                width={"100%"}
                height={"600px"}
            >
                <Container maxWidth='xl'>
                    <Box>
                        <Typography sx={{ color: "#fff", fontSize: "45px", fontWeight: "700", fontFamily: "'Lato',sans-serif" }}>
                            Our Services
                        </Typography>
                        <Typography sx={{ color: "red", fontSize: "25px", fontFamily: "'Lato',sans-serif", alignItems: "center", display: "flex" }}>
                            Home <ChevronRightIcon fontSize='large' /> Our Services
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Box>
                <Box py={10}>
                    <Typography sx={{fontSize:{lg:"48px",md:"28px",sm:"48px",xs:"30px"}, color: "red",fontWeight: "700", display: "flex", gap: "4px",justifyContent: "center" }}>
                        {ditails?.heding3} <Typography sx={{fontSize:{lg:"48px",md:"28px",sm:"48px",xs:"30px"},color: "black", fontWeight: "700", display: "flex" }}> {ditails?.heding}</Typography>  
                    </Typography>
                </Box>
                <Container maxWidth='xl'>
                    <Grid container item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"} spacing={5}>
                        <Grid item xs={12} sm={12} md={5} lg={5} display={"flex"} justifyContent={"center"}>
                        <Box display={"flex"} justifyContent={"center"}>
                             <img src={ditails?.img2} alt="" width={"90%"}/>
                        </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={7} lg={7} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <Box>
                                <Typography sx={{ fontWeight:"600",fontSize:{lg:"36px",md:"28px",sm:"36px"}, display: "flex" }}>{ditails?.heading2}
                                </Typography>
                                    <Typography sx={{pt:2,fontSize:{lg:"20px",md:"18px",sm:"20px",xs:"16px"},color:"#00000099"}}>A fire detector is a crucial safety device designed to detect the presence of 
                                        fire by sensing smoke, heat, or flames. These devices come in various types, 
                                        including smoke detectors, heat detectors, and flame detectors, each suited 
                                        for different environments. Smoke detectors are the most common, using 
                                        ionization or photoelectric sensors to detect smoke particles. Heat detectors 
                                        respond to a rise in temperature, while flame detectors sense infrared or 
                                        ultraviolet light from flames. Fire detectors are essential in early warning 
                                        systems, providing timely alerts to prevent damage and save lives.
                                    </Typography>
                                    <Typography sx={{pt:2,fontSize:{lg:"20px",md:"18px",sm:"20px",xs:"16px"},color:"#00000099"}}>A fire extinguisher is a portable device designed to extinguish small fires in 
                                    emergency situations. It works by discharging substances like water, foam,  or 
                                    chemicals to control and stop fires effectively and safely
                                    </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

            </Box>
            <Footer />
        </div>
    )
}

export default ServiceList
