import React from 'react';
import HomeHerosection from '../components/home/HomeHerosection';
import Mission from '../components/home/Mission';
import StatewiseClients from '../components/home/StatewiseClients';
import HomeService from '../components/home/HomeService';
import Header from '../commoncomponent/header/Header';
import Navbar from '../commoncomponent/header/Navbar';
import Footer from '../commoncomponent/footer/Footer';

function Home() {
  return (
    <div>
       <Header />
       <Navbar />
       <HomeHerosection />
       <Mission />
       <StatewiseClients />
       <HomeService />
       <Footer />
      
    </div>
  );
}

export default Home;
