import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import bgimg from '../../assets/images/service/redBG.png';
import clientimg from '../../assets/images/service/female-1.png';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function Clientsinquestions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <Box sx={{ marginTop: '50px', marginBottom: '50px' }}>
        <Container maxWidth='xl'>
          <Grid container item xs={12} sx={{display:"flex",justifyContent:"center"}}>
            <Grid item xs={12} md={6} sx={{ position: 'relative'}}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '50%',
                    height: '100%',
                    backgroundImage:`url(${bgimg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}/>
                <Box sx={{ padding: '20px', width: '86%', zIndex: 1 }}>
                  <img src={clientimg} alt="" style={{ width: '100%', height: '50%' }} />
                </Box>
              </Box>
              <Box sx={{ bgcolor: 'lightblue', display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '20%',
                    left: '50%',
                    transform: 'translateX(-20%)',
                    zIndex: '2',
                  }}
                >
                  <Box sx={{ padding: { xs: '14px', sm: '20px' }, backgroundColor: '#fff' }}>
                    <Typography sx={{ fontFamily: "'Lato', sans-serif", fontWeight: '500', fontSize: { xs: '12px', sm: '17px' } }}>
                      If You Need Help,
                    </Typography>
                    <Typography sx={{ fontFamily: "'Lato', sans-serif", fontWeight: '500', fontSize: { xs: '12px', sm: '17px' } }} gutterBottom>
                      Get A Consultation
                    </Typography>
                    <Typography sx={{ fontFamily: "'Lato', sans-serif", fontWeight: '600', fontSize: { xs: '12px', sm: '17px' }, color: '#E61F26' }}>
                      Get Started
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box>
                  <Typography sx={{ paddingTop: '15px', fontSize: { xs: '20px', md: '24px' }, fontWeight: '600', fontFamily: "'Lato', sans-serif", color: '#E61F26' }}>
                    Clients Inquiries
                  </Typography>
                  <Typography sx={{ paddingTop: '10px', fontWeight: '700', fontSize: { xs: '26px', md: '28px', lg: '34px' }, fontFamily: "'Lato', sans-serif" }}>
                    Related Questions
                  </Typography>
                  <Typography sx={{ fontFamily: "'Lato', sans-serif", fontSize: { xs: '16px', md: '17px', lg: '18px' }, paddingTop: '6px', color: '#999999', paddingBottom: '16px' }}>
                    Empowering Safety, Preventing Fires: Your Essential Resource for Fire
                    Prevention, Safety Tips, and Emergency Preparedness. Together, We Can Create
                    Safer Spaces for Everyone—Because Every Second Counts in a Fire.
                  </Typography>

                  <Accordion sx={{ marginTop: '30px' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      sx={{
                        display: 'flex',
                        backgroundColor: expanded === 'panel1' ? '#E61F26' : 'white',
                        borderLeft: '5px solid #E61F26',
                        color: expanded === 'panel1' ? 'white' : 'inherit',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />}
                      <Typography
                        sx={{
                          flexGrow: 1,
                          textAlign: 'start',
                          paddingLeft: '35px',
                          fontFamily: "'Lato', sans-serif",
                          fontWeight: 600,
                          fontSize: { xs: '16px', md: '19px' },
                        }}
                      >
                        What fire safety services do you offer?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontFamily: "'Lato', sans-serif",
                        fontSize: { xs: '16px', md: '19px' },
                        color: '#999999',
                      }}
                    >
                      <Typography sx={{ fontFamily: "'Lato', sans-serif", fontSize: { xs: '14px', md: '18px' }, marginLeft: '62px' }}>
                        We offer fire risk assessments, safety training, equipment installation, emergency planning, and compliance consultations for businesses.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion sx={{ marginTop: '30px' }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      sx={{
                        display: 'flex',
                        backgroundColor: expanded === 'panel2' ? '#E61F26' : 'white',
                        borderLeft: '5px solid #E61F26',
                        color: expanded === 'panel2' ? 'white' : 'inherit',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {expanded === 'panel2' ? <RemoveIcon /> : <AddIcon />}
                      <Typography
                        sx={{
                          flexGrow: 1,
                          textAlign: 'start',
                          paddingLeft: '35px',
                          fontFamily: "'Lato', sans-serif",
                          fontWeight: 600,
                          fontSize: { xs: '16px', md: '19px' },
                        }}
                      >
                        How can we improve our fire safety?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontFamily: "'Lato', sans-serif",
                        fontSize: { xs: '16px', md: '19px' },
                        color: '#999999',
                      }}
                    >
                      <Typography sx={{ fontFamily: "'Lato', sans-serif", fontSize: { xs: '14px', md: '18px' }, marginLeft: '62px' }}>
                        Regular training and assessments can significantly enhance fire safety measures in your establishment.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion sx={{ marginTop: '30px' }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      sx={{
                        display: 'flex',
                        backgroundColor: expanded === 'panel3' ? '#E61F26' : 'white',
                        borderLeft: '5px solid #E61F26',
                        color: expanded === 'panel3' ? 'white' : 'inherit',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}
                      <Typography
                        sx={{
                          flexGrow: 1,
                          textAlign: 'start',
                          paddingLeft: '35px',
                          fontFamily: "'Lato', sans-serif",
                          fontWeight: 600,
                          fontSize: { xs: '16px', md: '19px' },
                        }}
                      >
                        What are your qualifications in fire safety?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontFamily: "'Lato', sans-serif",
                        fontSize: { xs: '16px', md: '19px' },
                        color: '#999999',
                      }}
                    >
                      <Typography sx={{ fontFamily: "'Lato', sans-serif", fontSize: { xs: '14px', md: '18px' }, marginLeft: '62px' }}>
                        Our team consists of certified fire safety professionals with extensive training and experience in the field.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion sx={{ marginTop: '30px' }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                      sx={{
                        display: 'flex',
                        backgroundColor: expanded === 'panel4' ? '#E61F26' : 'white',
                        borderLeft: '5px solid #E61F26',
                        color: expanded === 'panel4' ? 'white' : 'inherit',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />}
                      <Typography
                        sx={{
                          flexGrow: 1,
                          textAlign: 'start',
                          paddingLeft: '35px',
                          fontFamily: "'Lato', sans-serif",
                          fontWeight: 600,
                          fontSize: { xs: '16px', md: '19px' },
                        }}
                      >
                        What are the costs for fire inspections?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontFamily: "'Lato', sans-serif",
                        fontSize: { xs: '16px', md: '19px' },
                        color: '#999999',
                      }}
                    >
                      <Typography sx={{ fontFamily: "'Lato', sans-serif", fontSize: { xs: '14px', md: '18px' }, marginLeft: '62px' }}>
                        Costs vary depending on the size and type of the property; please contact us for a detailed quote.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Clientsinquestions;