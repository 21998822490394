import React from 'react';
import { Box, Grid, Typography, Avatar, Button, Stack, Container } from '@mui/material';
import { styled } from '@mui/system';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Emergencypic from '../../assets/images/aboutus/Emergency.png'
import badge from '../../assets/images/aboutus/badge.png'



const StyledAvatar = styled(Avatar)({
  width: 80,
  height: 80,
  border: '4px solid white',
});

const EmergencyButton = styled(Button)({
  color: 'red',
  fontWeight: 'bold',
  fontSize: '20px',
});

const Emergency = () => {
  return (
    <Box sx={{py:10}}>
    <Container maxWidth='xl'>
      <Grid container item xs={12} spacing={4}>
        
        <Grid item xs={12} md={6}>
          <img  src={Emergencypic} width={"100%"}/>
        </Grid>


        <Grid item xs={12} md={6}>
          <Box>
            <Typography color={"#00000099"} sx={{width:"100%",fontSize:{lg:"24px",md:"20px",sm:"20px",xs:"18px"},fontWeight:'500',textDecoration:"underline",textDecorationColor:"red",textDecorationThickness:"3px",textUnderlineOffset:"10px"}}>
              About Our Red Iron
            </Typography>
            <Typography fontWeight="bold" sx={{fontSize:{lg:"55px",md:"48px",sm:"40px",xs:"32px"},width:{lg:"90%",md:"90%",xs:"100%"}}} py={2}> 
              Learn More About Our Red Iron
            </Typography>
            <Typography variant="body1" paragraph color={'#00000099'}>
              At [Red Iron], we are dedicated to safeguarding lives and property through comprehensive fire safety solutions. Our expert team provides assessments, training, and cutting-edge equipment, ensuring compliance and preparedness for any emergency, creating safer environments for businesses and communities.
            </Typography>
            <Typography variant="body1" paragraph color={"#00000099"}>
              We specialize in delivering reliable fire safety solutions tailored to meet your needs. With a commitment to excellence, our team offers fire risk assessments, safety training, advanced equipment installation, and emergency planning to ensure maximum protection.
            </Typography>

    
            <Box display="flex" alignItems="center" mt={4}>
              <StyledAvatar src={badge} alt="Emergency Contact" />
              <Box ml={2}>
                <Typography variant="body2" color={"#00000099"}>
                  We Are Available 24 Hours
                </Typography>
                <Box sx={{display:{lg:"flex",md:"flex",sm:"flex",xs:"block"},justifyContent:"center",alignItems:"center"}}>
                <Typography variant="h6" fontWeight="bold" color="black">
                  For Emergency: 
                </Typography>
                <EmergencyButton
                  startIcon={<LocalPhoneIcon />}
                >
                  020 3797 3053
                </EmergencyButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      </Container>
    </Box>
  );
};

export default Emergency;
