import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

function ContactForm() {
  return (
    <Box sx={{pt:{lg:10,md:8,sm:5,xs:5}}}>
      <Container maxWidth='xl'>
        <Grid item container xs={12} spacing={10} display={"flex"} justifyContent={"center"}>
          <Grid item xs={12} md={6} lg={6}>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }} gap={4}>
              <Box sx={{ flex: 1 }}>
                <TextField id="outlined-multiline-flexible" label="Your Name*" multiline maxRows={4} sx={{ width: "100%", fontFamily: "'Lato', sans-serif" }} />
              </Box>
              <Box sx={{ flex: 1 }}>
                <TextField id="outlined-multiline-flexible" label="Email iD*" multiline maxRows={4} sx={{ width: "100%", fontFamily: "'Lato', sans-serif" }} />
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, paddingTop: "40px" }} gap={4}>
              <Box sx={{ flex: 1 }}>
                <TextField id="outlined-multiline-flexible" label="Your Phone" multiline maxRows={4} sx={{ width: "100%", fontFamily: "'Lato', sans-serif" }} />
              </Box>
              <Box sx={{ flex: 1 }}>
                <TextField id="outlined-multiline-flexible" label="Subject" multiline maxRows={4} sx={{ width: "100%", fontFamily: "'Lato', sans-serif" }} />
              </Box>
            </Box>

            <Box sx={{ display: "flex", paddingTop: "40px" }} gap={3}>
              <Box sx={{ flex: 1 }}>
                <TextField id="outlined-multiline-static" label="Your Message*" multiline rows={8} sx={{ width: "100%", fontFamily: "'Lato', sans-serif", minHeight: "160px" }} />
              </Box>
            </Box>
            <Box sx={{ display: "flex", paddingTop: "20px" }} gap={3}>
              <Box>
                <Button variant="contained" disableElevation sx={{ backgroundColor: "#E61F26", fontFamily: "'Lato', sans-serif", fontSize: { xs: "10px", md: "14px" }, borderRadius: "30px", padding: "15px 25px", height: "auto" }}>
                  SUBMIT COMMENT
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={5} pt={"40px"}>
            <Box>
              <Typography sx={{ fontFamily: "'Lato', sans-serif", fontSize: { xs: "28px", md: "46px" }, fontWeight: "900" }}>
                Drop us a Message
              </Typography>
              <Typography sx={{ fontFamily: "'Lato', sans-serif", fontSize: { xs: "14px", md: "20px" }, color: "#828282" }}>
                Have questions? Contact us today for expert fire safety guidance and solutions!
              </Typography>
              <Box sx={{ display: "flex", padding:{lg:"20px 0px",md:"20px 0px",sm:"10px 0px",xs:"10px 0px"}, borderBottom: "1px solid #B2B2B2", width: "100%",}} gap={5}>
                <PhoneIcon sx={{ fontSize:{ xs: "40px", md: "50px",lg:"60px" }, color: "white", backgroundColor: "#E61F26", borderRadius: "50%", padding:{lg:"15px",md:"12px",sm:"10px",xs:"5px"} }}  />
                <Typography sx={{ fontSize: { xs: "14px",sm:"16px", md: "20px" }, display: "flex", alignItems: "center" }}>020 3797 3053</Typography>
              </Box>
              <Box sx={{ display: "flex",padding:{lg:"20px 0px",md:"20px 0px",sm:"10px 0px",xs:"10px 0px"}, borderBottom: "1px solid #B2B2B2", width: "100%"}} gap={5}>
              <EmailIcon sx={{ fontSize:{ xs: "40px", md: "50px",lg:"60px" }, color: "white", backgroundColor: "#E61F26", borderRadius: "50%", padding:{lg:"15px",md:"12px",sm:"10px",xs:"5px"} }}   />
                <Typography sx={{ fontSize: { xs: "14px",sm:"16px", md: "20px" }, display: "flex", alignItems: "center" }}>rediron@gmail.com</Typography>
              </Box>
              <Box sx={{ display: "flex",padding:{lg:"20px 0px",md:"20px 0px",sm:"10px 0px",xs:"10px 0px"}, width: "100%" }} gap={5}>
                <LocationOnIcon sx={{ fontSize:{ xs: "40px", md: "50px",lg:"60px" }, color: "white", backgroundColor: "#E61F26", borderRadius: "50%", padding:{lg:"15px",md:"12px",sm:"10px",xs:"5px"} }} />
                <Typography sx={{ fontSize: { xs: "14px",sm:"16px", md: "20px" }, display: "flex", alignItems: "center" }}>
                    Akshya Nagar 1st Block 1st Cross, Rammurthy Nagar, Bangalore-560016
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ContactForm;