
import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import firefighter from '../../assets/images/aboutus/firefighter-1.png';

const TeamMembers = () => {
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Box py={5}>
            <Container maxWidth="xl">
                <Box mb={2}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                        <Typography
                            variant="h6"
                            sx={{ color: '#00000099', borderBottom: '3px solid #E61F26', width: '143px' }}
                        >
                            Team Members
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h4" sx={{ fontWeight: '600', fontFamily: 'lato,sans-serif' }}>
                            Our Firefighters
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Slider  {...settings}>
                        <Box position={"relative"}>
                           <Box marginRight={2}>
                              <img src={firefighter} style={{width:"100%"}}/>
                           </Box>
                           <Box sx={{backgroundColor:"#fff",padding:"20px 25px",borderBottom:"5px solid #E61F26",width:"50%",position:"absolute",bottom:"0",left:"23%"}}>
                              <Typography variant='h5' sx={{fontWeight:"600",textAlign:"center"}}>Kevin Martin</Typography>
                              <Typography sx={{textAlign:"center",color:"#00000099"}}>Firefighter</Typography>
                           </Box>

                        </Box>
                        <Box position={"relative"}>
                           <Box marginRight={2}>
                              <img src={firefighter} style={{width:"100%"}}/>
                           </Box>
                           <Box sx={{backgroundColor:"#fff",padding:"20px 25px",borderBottom:"5px solid #E61F26",width:"50%",position:"absolute",bottom:"0",left:"23%",zIndex:1}}>
                              <Typography variant='h5' sx={{fontWeight:"600",textAlign:"center"}}>Kevin Martin</Typography>
                              <Typography sx={{textAlign:"center",color:"#00000099"}}>Firefighter</Typography>
                           </Box>

                        </Box>
                        <Box position={'relative'}>
                           <Box marginRight={2}>
                              <img src={firefighter} style={{width:"100%"}}/>
                           </Box>
                           <Box sx={{backgroundColor:"#fff",padding:"20px 25px",borderBottom:"5px solid #E61F26",width:"50%",position:"absolute",bottom:"0",left:"23%"}}>
                              <Typography variant='h5' sx={{fontWeight:"600",textAlign:"center"}}>Kevin Martin</Typography>
                              <Typography sx={{textAlign:"center",color:"#00000099"}}>Firefighter</Typography>
                           </Box>


                        </Box>
                        <Box position={"relative"}>
                           <Box marginRight={2}>
                              <img src={firefighter} style={{width:"100%"}}/>
                           </Box>
                           <Box sx={{backgroundColor:"#fff",padding:"20px 25px",borderBottom:"5px solid #E61F26",width:"50%",position:"absolute",bottom:"0",left:"23%",zIndex:1}}>
                              <Typography variant='h5' sx={{fontWeight:"600",textAlign:"center"}}>Kevin Martin</Typography>
                              <Typography sx={{textAlign:"center",color:"#00000099"}}>Firefighter</Typography>
                           </Box>

                        </Box>
                    </Slider>
                </Box>
            </Container>
        </Box>
    );
};

export default TeamMembers;

