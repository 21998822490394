import React from 'react';
import ContactHerosection from '../components/contact/ContactHerosection';
import Header from '../commoncomponent/header/Header';
import Navbar from '../commoncomponent/header/Navbar';
import Footer from '../commoncomponent/footer/Footer';
import ContactForm from '../components/contact/ContactForm';

function Contact() {
  return (
    <div>
      <Header />
      <Navbar />
      <ContactHerosection />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default Contact;
