import React from 'react';
import Header from './commoncomponent/header/Header';
import Navbar from './commoncomponent/header/Navbar';
import Home from './pages/Home';
import Footer from './commoncomponent/footer/Footer';
import AboutUs from './pages/AboutUs';
import Service from './pages/Service';
import { Route, Routes, useLocation  } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Contact from './pages/Contact';
import ServiceList from './components/service/ServiceList';
import PrivacyPolicy from './commoncomponent/usefulllinks/PrivacyPolicy';
import TermsConditions from './commoncomponent/usefulllinks/TermsConditions';


function App() {


  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }

  return (
    <div>
        
        {/* <Contact /> */}
        {/* <Service /> */}
        {/* <AboutUs /> */}
        {/* <PrivacyPolicy /> */}
        <ScrollToTop />
        <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/ourservices' element={<Service />}></Route>
            <Route path='/aboutus' element={<AboutUs />}></Route>
            <Route path='/contactus' element={<Contact />}></Route>
            <Route path='/support' element={<Home />}></Route>
            <Route path='/servicelist/:id' element={<ServiceList />}></Route>
            <Route path='/privacypolicy' element={<PrivacyPolicy />}></Route>
            <Route path='/termsconditions' element={<TermsConditions />}></Route>
        </Routes>


    </div>  
  );
}

export default App;
