import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import live from '../../assets/images/aboutus/Live.png'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

function ProtectingLives() {
    return (
        <Box sx={{ position: 'relative', width: '100%', height: '700px' }}>
            
            <Box
                sx={{
                    backgroundImage: `url(${live})`,
                    width: "100%",
                    height: "100%",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 0, 
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#000000A6', 
                        zIndex: 1, 
                    }
                }}
            />

            <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "end", height: "450px", position: "relative" }}>
                    <Box>
                        
                        <Box sx={{ padding: '0 10px 0 0', display: "flex", justifyContent: "center" }}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: '70px', 
                                    height: '70px',
                                    backgroundColor: '#E61F26',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mb:5
                                }}
                            >
                                <PlayArrowRoundedIcon
                                    sx={{
                                        fontSize: '50px',
                                        color: '#fff',
                                        zIndex: 1, 
                                    }}
                                />

                                <Typography
                                    component="span"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        zIndex: 0,
                                        width: '100%',
                                        height: '100%',
                                        background: '#fff',
                                        borderRadius: 'inherit',
                                        opacity: 0.8,
                                        animation: 'pulseAnimate 2s ease-out infinite',
                                        zIndex: -1
                                    }}
                                ></Typography>
                            </Box>
                        </Box>

                    
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <Typography variant='h4' sx={{ fontWeight: "500", color: "#fff", textAlign: "center", width: "85%" }}>
                                Protecting Lives, Preventing Fires, Ensuring Safety.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default ProtectingLives;

