import { Container, Box, Typography } from '@mui/material'
import bg from '../../assets/images/aboutus/aboutBG.png'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function AboutHerosection() {
    return (
        <div>
            <Box sx={{
                backgroundImage: `linear-gradient(90deg,rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center"
            }} 
            width={"100%"} 
            height={"600px"}>
                <Container maxWidth='xl'>
                    <Box>
                        <Typography sx={{ color: "#fff", fontSize: "45px", fontWeight: "700", fontFamily: "'Lato',sans-serif" }}>
                            About Us
                        </Typography>
                        <Typography sx={{ color: "red", fontSize: "25px", fontFamily: "'Lato',sans-serif", alignItems: "center", display: "flex" }}>
                            Home <ChevronRightIcon fontSize='large' /> About Us
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </div>
    );
}

export default AboutHerosection;
