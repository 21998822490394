import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import Header from '../header/Header';
import Navbar from '../header/Navbar';
import Footer from '../footer/Footer';
import image from '../../assets/images/contact/contactBG.png'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function PrivacyPolicy() {
  return (
<>
    <Header />
    <Navbar />
    <Box
        sx={{
            backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.755), rgba(0, 0, 0, 0.755), rgba(0, 0, 0, 0)), url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center"
        }}
        width={"100%"}
        height={"600px"}
    >
        <Container maxWidth='xl'>   
            <Box>
                <Typography sx={{ color: "#fff", fontSize: "45px", fontWeight: "700", fontFamily: "'Lato',sans-serif" }}>
                   Privacy Policy
                </Typography>
                <Typography sx={{ color: "red", fontSize: "25px", fontFamily: "'Lato',sans-serif", alignItems: "center", display: "flex" }}>
                    Home <ChevronRightIcon fontSize='large' /> Privacy Policy
                </Typography>
            </Box>
        </Container>
    </Box>

    <Box marginTop={8}>
        <Container maxWidth='xl'>
            <Box sx={{paddingBottom:{xs:'30px', md:'60px'}}}>
                <Typography sx={{fontSize:{xs:'30px', md:'36px'},fontFamily:"'Lato', sans-serif",fontWeight:'700',textDecoration:"underline",textDecorationColor:"#E61F26",textUnderlineOffset: '20px'}}>
                    <span style={{color:'#E61F26'}}>Privacy </span>Policy
                </Typography>
            </Box>

            <Box sx={{color:'#000000B2',fontFamily:"'Lato', sans-serif"}}>
                <Typography sx={{fontSize:{xs:'16px',sm:'18px',md:'20px',lg:'20px'},paddingBottom:'28px'}}>
                    At [Red Iron], your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you interact with our website and services.
                </Typography>
                <Typography sx={{fontSize:{xs:'16px',sm:'18px',md:'20px',lg:'20px'},paddingBottom:'28px'}}>
                    We collect personal data to provide and improve our fire safety services. Information such as your name, contact details, and address may be collected when you request information, book services, or subscribe to our newsletters. Additional data such as IP addresses and browsing behavior is collected via cookies to enhance website performance and user experience. We only collect data relevant to the services you request or as necessary for legitimate business purposes.
                    All information you provide is stored securely, and we employ the latest security measures to prevent unauthorized access, use, or disclosure of your data. We do not sell, rent, or share your personal data with third parties unless required by law or for fulfilling your requested services (such as third-party fire safety equipment providers or consultants).
                </Typography>
                <Typography sx={{fontSize:{xs:'16px',sm:'18px',md:'20px',lg:'20px'},paddingBottom:'28px'}}>
                    You have the right to access, correct, or delete your personal information from our records at any time. If you wish to opt out of our communications or have concerns about how your data is being used, you can contact our support team via email at [rediron@gmail.com].
                </Typography>
                <Typography sx={{fontSize:{xs:'16px',sm:'18px',md:'20px',lg:'20px'},paddingBottom:'28px'}}>
                    By using our website, you consent to the collection and use of your data as outlined in this policy. We reserve the right to modify this Privacy Policy at any time, and any changes will be promptly posted on this page. Continued use of our website after changes implies your acceptance of the updated policy.
                </Typography>
            </Box>
        </Container>
    </Box>
    <Footer />
 </>
  )
}

export default PrivacyPolicy;               