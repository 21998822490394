import React from "react";
import { Grid, Typography, Box, Container } from "@mui/material";
import mission1 from '../../assets/images/home/mission1.webp';
import mission2 from '../../assets/images/home/mission2.webp';

const images = {
  mission1,
  mission2,
};

const Mission = () => {
  return (
    <Box sx={{py:10}}>
      <Container maxWidth='xl'>
        <Grid container spacing={4} alignItems="center" justifyContent={"center"}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column',sm:'row' ,md: 'row' },
                justifyContent: 'space-between',
              }}
            >
              {/*Products */}
              <Box
                sx={{
                  position: "relative",
                  textAlign: 'center',
                  padding: '30px',
                  width: '100%',
                }}
              >
                <Box
                  component="img"
                  src={images.mission1}
                  alt="Firefighter in action"
                  height="360"
                  sx={{ width: '100%', filter: "brightness(80%)", objectFit: "cover" }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: { xs: -5,sm:26,md:10 ,lg: -20 },
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    p: { xs: 1, md: 2 },
                    width: { xs: '70%', md: 'auto' },
                    maxWidth: '300px', 
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <Typography
                    color="#E61F26"
                    fontWeight="900"
                    sx={{
                      borderBottom: '4px solid black',
                      paddingBottom: "5px",
                      marginBottom: '5px',
                      fontSize: { xs: "16px",sm:"18px" ,md: "18px" },
                      fontFamily: "'Lato', sans-serif",
                      textAlign: 'center',
                    }}
                  >
                    PRODUCTS
                  </Typography>
                  <Typography sx={{fontSize: { xs: "18px", md: "22px" },fontFamily: "'Lato', sans-serif",fontWeight:'700',color:'black'}}>
                    85+
                  </Typography>
                </Box>
              </Box>

              {/* Happy Clients */}
              <Box
                sx={{
                  position: 'relative',
                  textAlign: 'center',
                  width: '100%',
                  paddingTop: { lg: "0px", xs: "50px" }
                }}
              >
                <Box
                  component="img"
                  src={images.mission2}
                  alt="Group of happy clients"
                  height="360"
                  sx={{ width: '100%', filter: 'brightness(80%)', objectFit: "cover" }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: { xs: -36, sm: -36, md: -36 },
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    p: { xs: 1, md: 2 },
                    width: { xs: '90%', md: 'auto' },
                    maxWidth: '300px',
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <Typography
                    color="#E61F26"
                    fontWeight="900"
                    sx={{
                      borderBottom: '4px solid black',
                      paddingBottom: "5px",
                      marginBottom: '5px',
                      fontSize: {  xs: "16px", sm:"18px", md: "18px"},
                      fontFamily: "'Lato', sans-serif",
                      textAlign: 'center',
                      whiteSpace: "nowrap",
                    }}
                  >
                    HAPPY CLIENTS
                  </Typography>
                  <Typography sx={{fontSize: { xs: "18px", md: "22px" },fontFamily: "'Lato', sans-serif",fontWeight:'700',color:'black'}}>
                    69,654+
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Vision and Mission */}
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: "center", pl: { xs: 0, md: 2 }, pt: { lg: 0, md: 0, xs: 5 } }}>
              <Typography gutterBottom sx={{ fontSize: { xs: '24px', sm: '28px', md: '26px' }, fontWeight: '700', fontFamily: "'Lato', sans-serif", color: "#E61F26", textDecoration: 'underline', textDecorationColor: 'black',textDecorationThickness:"4px",textUnderlineOffset: '16px',lineHeight:'80px' }}>
                OUR VISION
              </Typography>
              <Typography sx={{ fontSize: { xs: '16px', sm: '18px', md: '17px', lg: '20px' }, fontFamily: "'Lato', sans-serif", paddingBottom: {xs:'40px',md:'40px',lg:'60px'}}}>
                To be the most customer-centric company on the planet, where customers can find and discover anything they might want to buy.
              </Typography>

              <Typography gutterBottom sx={{ fontSize: { xs: '24px', sm: '28px', md: '26px' }, fontWeight: '700', fontFamily: "'Lato', sans-serif", color: "#E61F26", textDecoration: 'underline', textDecorationColor: 'black',textDecorationThickness:"4px",textUnderlineOffset: '16px',lineHeight:'80px' }}>
                OUR MISSION
              </Typography>
              <Typography sx={{ fontSize: { xs: '16px', sm: '18px', md: '17px', lg: '20px' }, fontFamily: "'Lato', sans-serif", paddingBottom: "70px" }}>
                We understand the challenges faced by security professionals and seek to find solutions through new technologies and breakthrough innovations. We are committed to continuous enhancement and improvement.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Mission;
