import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import homeservice from '../../assets/images/home/BgImage.png'
import homeservice1 from '../../assets/images/home/service1.png'
import homeservice2 from '../../assets/images/home/service2.png'
import homeservice3 from '../../assets/images/home/service3.png'
import homeservice4 from '../../assets/images/home/service4.png'
import homeservice5 from '../../assets/images/home/service5.png'
import homeservice6 from '../../assets/images/home/service6.png'

function HomeService() {

  const ourservice = [
    {
      img: homeservice1,
      heding: "Fire Detector"
    },
    {
      img: homeservice2,
      heding: "Fire Extinguisher"
    },
    {
      img: homeservice3,
      heding: "Fire Hose Reels"
    },
    {
      img: homeservice4,
      heding: "Manual Call Point"
    },
    {
      img: homeservice5,
      heding: "Hydrant System"
    },
    {
      img: homeservice6,
      heding: "Fire Blankets"
    },
  ];

  return (
    <Box py={15}>
      <Box sx={{ backgroundImage: `url(${homeservice})`, height: "100%" }}>
        <Container maxWidth='xl'>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant={'h4'} mt={10} mb={5} sx={{
              color: "red", paddingBottom: "10px", fontWeight: "800",
              fontFamily: "'Lato',sans-serif", borderBottom: "3px solid white", width: "250px", textAlign: "center"
            }}>
              OUR SERVICES
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant={'h3'} sx={{
              color: "white", fontWeight: "800",
              fontFamily: "'Lato',sans-serif", textAlign: "center"
            }}>
              We Serve The Best Service
            </Typography>
          </Box>
          <Box py={5} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography sx={{
              color: "#D4D0CC", fontFamily: "'Lato', sans-serif",
              fontSize: { xs: "18px", sm: "20px", md: "24px" }, width: { lg: "35%", md: "70%", sm: "80%", xs: "100%" }, textAlign: "center"
            }}>
              Comprehensive fire safety solutions, protecting lives and property effectively.
            </Typography>
          </Box>

          <Grid container spacing={5}>
            {ourservice.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  sx={{
                    backgroundImage: `linear-gradient(360deg,rgba(0,0,0,0.955),rgba(0,0,0,0)), url(${item.img})`,
                    width: '100%',
                    height: '300px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography variant='h5' sx={{
                    color: "white", padding: "0px 0px 20px 20px",
                    fontWeight: "900", fontFamily: "'Lato',sans-serif"
                  }}>
                    {item.heding}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default HomeService;
