import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import a4 from '../../assets/images/aboutus/badge.png'
import a6 from '../../assets/images/aboutus/Review.png'
import { Avatar, Box, Container, Rating, Typography } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

function TheirTestimonials() {

    const review = [
         {
            avatar:a4,
            avatar2:a6,
         },
         {
            avatar:a4,
            avatar2:a6,
         },
         {
            avatar:a4,
            avatar2:a6,
         },
         {
            avatar:a4,
            avatar2:a6,
         },
    ]



    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrow: true,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="container " >
            <Box sx={{py:4}}>
            <Container maxWidth="xl">
            
            <Box sx={{ display: "flex", justifyContent: "center", py:3 }}>
                    <Typography variant='h6' sx={{ color: "#00000099", borderBottom: "3px solid #E61F26", width: "170px" }}>
                        Their Testimonials
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center",pb:2 }}>
                     <Typography variant='h4' sx={{ fontWeight: "600", fontFamily: "lato,sans-serif" }}>
                        What They Say
                     </Typography>
                 </Box>
           
            <Slider {...settings} >
        

                {review.map((item,index) => (
                        <>
                        <Box display={"flex"} position={"relative"}>
                        <Avatar
                            src={item.avatar}
                            sx={{ width: 82, height: 82 }}
                        />
                        <Box sx={{ backgroundColor: "#ECECEC", width: "88%", py: 1, pl: 10, position: "absolute", zIndex: -1, bottom: 0, left: "10%" }}>
                            <Typography variant='h5' >Mike Hardson</Typography>
                            <Typography variant='body2' color={"#E61F26"}>Our Citizen</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ backgroundColor: "#ECECEC", mt: 1 ,marginRight:1}}>
                        <Typography variant='body1' sx={{ p: 2,fontFamily:"'Lato',sans-serif"}}>Ensuring safety, preventing fires our expert team delivers
                            comprehensive fire protection services, from risk assessments
                            to emergency planning. Trust us to safeguard your property,
                            people, and peace of mind with proactive solutions.</Typography>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Rating name="full-rating" defaultValue={5} sx={{ color: "#E61F26", pl: 2 }} readOnly />
                            <Box sx={{ position: "relative" }}>
                                <img src={item.avatar2} style={{ width: "110px", height: "70px" }} />
                                <Typography><FormatQuoteIcon sx={{ fontSize: "40px", color: "#E61F26", position: "absolute", zIndex: 1, bottom: "10%", left: "30%" }} /></Typography>
                            </Box>
                        </Box>
                    </Box>
                        </>
                    ))}
            </Slider>
            </Container>
            </Box>  
    </div>
    )
}

export default TheirTestimonials